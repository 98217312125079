import { CloseOutlined } from '@ant-design/icons';
import { ProductFamilySummary } from '@omniafishing/core';
import pluralize from 'pluralize';
import React from 'react';
import AnimateHeight from 'react-animate-height';
import { useExperiment } from 'statsig-react';
import { OmniaButton } from '../../components/omnia_button/omnia_button';
import { useQueryString } from '../../hooks/use_query_string';
import { useResponsive } from '../../hooks/use_responsive';
import { GlobalQueryParams } from '../../lib/query_string';
import { WebAnalytics } from '../../lib/web_analytics';

interface FishingReportSummaryProps {
  fishingReportsCount: number;
  productFamilySummary: ProductFamilySummary;
}

export const FishingReportSummary = (props: FishingReportSummaryProps) => {
  const { fishingReportsCount, productFamilySummary } = props;

  const { isDesktop, isMobile } = useResponsive();
  const { replaceQueryString, getCurrentQuery } = useQueryString();
  const [selectedAttributeName, setSelectedAttributeName] = React.useState<string | null>(null);
  const [height, setHeight] = React.useState<0 | 'auto'>(0);
  const [summaryExpanded, setSummaryExpanded] = React.useState<boolean>(isDesktop);
  const truncatedView = isMobile && !summaryExpanded;

  const currentQuery = getCurrentQuery<{ ai_content: 'summary' | 'description' }>();
  const queryContent = currentQuery.ai_content;
  const llmContent = useExperiment('llm_summary_vs_description').config.get<
    'summary' | 'description'
  >('content', 'description');

  if (queryContent === 'description' || !(llmContent === 'summary' || queryContent === 'summary')) {
    return null;
  }

  const { summary, themes } = productFamilySummary;
  const selectedAttribute = themes.find((t) => t.name === selectedAttributeName);

  return (
    <div
      className={`relative border rounded-xl p-4 bg-[#f4f4f4] ${
        truncatedView && 'h-[220px] overflow-hidden'
      }`}
    >
      <p className="mb-2 font-bold font-termina text-[#002BFF] leading-tight">
        Anglers Say:{' '}
        <span className="block text-[11px] font-normal font-sans">
          From {fishingReportsCount || ''} Fishing {pluralize('Report', fishingReportsCount)}
        </span>
      </p>

      <p className={`font-[15px] mb-6`}>{summary}</p>

      <p className="font-bold mb-2 font-termina text-[11px] flex items-center gap-2">
        Select to learn more{' '}
        <span className="bg-gradient-to-r from-[#002BFF] to-[#FF2A00] h-1 flex-1" />
      </p>

      <ol
        className={`flex gap-2 mb-3 items-start ${isMobile && 'flex-col'} ${
          isDesktop && 'flex-row flex-wrap'
        }`}
      >
        {themes.map((theme) => {
          const selected = selectedAttributeName === theme.name && height === 'auto';
          return (
            <li
              key={theme.name}
              className={`rounded-full overflow-hidden p-1 border-1 border-black/15 shadow-sm select-none bg-white cursor-pointer text-[13px] bg-clip-padding ${
                selected && 'bg-gradient-to-r from-[#002BFF] to-[#FF2A00] border-transparent'
              }`}
              onClick={() => {
                if (selected) {
                  setHeight(0);
                } else {
                  setHeight('auto');
                }
                setSelectedAttributeName(theme.name);
              }}
            >
              <span className={`block py-1 px-3 rounded-full ${selected && 'bg-black text-white'}`}>
                {theme.name}
              </span>
            </li>
          );
        })}
      </ol>
      <AnimateHeight height={height} easing="ease-out" duration={200}>
        <div className="bg-gradient-to-b from-[#002BFF] to-[#FF2A00] rounded-xl p-1">
          {selectedAttribute && (
            <div className={`p-3 relative rounded-lg bg-white`}>
              <p className="flex gap-1 mb-2 mr-7">
                <span className="text-[11px]">
                  <strong className="block font-termina text-[15px]">
                    {selectedAttribute.name}
                  </strong>
                </span>
              </p>
              <p className="mb-2">{selectedAttribute.summary}</p>
              <ul className="flex flex-col gap-3">
                {selectedAttribute.evidence.map((e) => {
                  return (
                    <li key={e.source_fishing_report_id} className="roudned-xl">
                      <q className="quotes-none block p-3 bg-[#E9EEEC] text-[#061622]/65 rounded-xl before:content-['“'] before:font-termina before:font-bold before:block before:text-[#8A9397] before:leading-none">
                        {e.excerpt}{' '}
                        <span
                          className="text-[#002BFF] cursor-pointer"
                          onClick={() => {
                            WebAnalytics.fishingReportReadMoreClicked(e.source_fishing_report_id);
                            replaceQueryString({
                              [GlobalQueryParams.report_id]: e.source_fishing_report_id,
                            });
                          }}
                        >
                          Read&nbsp;report
                        </span>
                      </q>
                    </li>
                  );
                })}
              </ul>
              <OmniaButton
                size="sm"
                shape="circle"
                kind="tertiary"
                className="absolute top-3 right-3"
                onClick={() => {
                  setHeight(0);
                }}
              >
                <CloseOutlined className="flex-grow-0 flex-shrink-0" />
              </OmniaButton>
            </div>
          )}
        </div>
      </AnimateHeight>

      {truncatedView && (
        <div className="absolute bottom-0 left-0 right-0 block p-3 pt-20 z-10 bg-gradient-to-t from-[#f4f4f4] from-50% to-[transparent]">
          <OmniaButton
            kind="tertiary"
            size="md"
            block
            onClick={() => {
              setSummaryExpanded(true);
            }}
          >
            Read More
          </OmniaButton>
        </div>
      )}
    </div>
  );
};
