import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useCart } from '../../hooks/use_cart';
import { apiV1 } from '../../lib/api';
import { isBase64 } from '../../lib/base64';
import { hasAccessToken } from '../../redux/auth';
import { fetchCheckout } from '../../redux/cart';

export const UserCartAssociator = (): null => {
  const dispatch = useDispatch();
  const { checkoutId } = useCart();
  const isLoggedIn = useSelector(hasAccessToken);

  useEffect(() => {
    if (isLoggedIn && checkoutId) {
      const checkoutIdToAssociate = isBase64(checkoutId) ? atob(checkoutId) : checkoutId;
      apiV1.cartAssociate({ checkout_id: checkoutIdToAssociate }).then(() => {
        dispatch(fetchCheckout(checkoutId));
      });
    }
  }, [checkoutId, isLoggedIn]);

  return null;
};
