import { ProductFamilyDescription } from '@omniafishing/core';
import React from 'react';
import { useExperiment } from 'statsig-react';
import { OmniaButton } from '../../components/omnia_button/omnia_button';
import { useQueryString } from '../../hooks/use_query_string';
import { useResponsive } from '../../hooks/use_responsive';

interface AiDescriptionProps {
  productFamilyDescription: ProductFamilyDescription | null;
}

export const AiDescription = (props: AiDescriptionProps) => {
  const { productFamilyDescription } = props;
  const { isDesktop, isMobile } = useResponsive();
  const { getCurrentQuery } = useQueryString();
  const [descriptionExpanded, setDescriptionExpanded] = React.useState<boolean>(isDesktop);
  const truncatedView = isMobile && !descriptionExpanded;

  const currentQuery = getCurrentQuery<{ ai_content: 'summary' | 'description' }>();
  const queryContent = currentQuery.ai_content;
  const llmContent = useExperiment('llm_summary_vs_description').config.get<
    'summary' | 'description'
  >('content', queryContent);

  if (
    queryContent === 'summary' ||
    !(llmContent === 'description' || queryContent === 'description')
  ) {
    return null;
  }

  const { key_points, blurb } = productFamilyDescription;

  return (
    <div
      className={`relative border rounded-xl p-4 bg-[#f4f4f4] ${
        truncatedView && 'h-[220px] overflow-hidden'
      }`}
    >
      <p className="mb-2 font-bold font-termina text-[#002BFF] leading-tight">At a Glance:</p>

      <p className={`font-[15px] mb-6`}>{blurb}</p>

      <ul
        className={`flex gap-2 mb-3 items-start ${isMobile && 'flex-col'} ${
          isDesktop && 'flex-row flex-wrap'
        }`}
      >
        {key_points.map((point) => {
          return (
            <li
              key={point}
              className={`rounded-full overflow-hidden p-1 border-1 border-black/15 shadow-sm select-none bg-white text-[13px] bg-clip-padding`}
            >
              <span className={`block py-1 px-3 rounded-full`}>{point}</span>
            </li>
          );
        })}
      </ul>

      {truncatedView && (
        <div className="absolute bottom-0 left-0 right-0 block p-3 pt-20 z-10 bg-gradient-to-t from-[#f4f4f4] from-50% to-[transparent]">
          <OmniaButton
            kind="tertiary"
            size="md"
            block
            onClick={() => {
              setDescriptionExpanded(true);
            }}
          >
            Read More
          </OmniaButton>
        </div>
      )}
    </div>
  );
};
