import { SelectedOption } from '@omniafishing/core';
import _ from 'lodash';

// ========================================================================== //
// Main routes
// ========================================================================== //

// Probably change enums to this syntax:

// export const RoutePaths = {
//   ABOUT: '/about',
//   AMBASSADOR_APPLICATION: '/ambassador-application',
//   AMBASSADOR_APPLICATION_FORM: '/ambassador-application-form',
//   AMBASSADORS: '/ambassadors',
// } as const;
// export type RoutePaths = typeof RoutePaths[keyof typeof RoutePaths]

export enum RoutePaths {
  ABOUT = '/about',
  AMBASSADOR_APPLICATION = '/ambassador-application',
  AMBASSADOR_APPLICATION_FORM = '/ambassador-application-form',
  AMBASSADORS = '/ambassadors',
  APP = '/app',
  ARTICLES = '/a',
  BEST_OF_YEAR = '/best-of-the-year',
  BEST_SELLERS = '/best-sellers',
  BIG_SELECTION = '/big-selection',
  BLACK_FRIDAY_CYBER_MONDAY = '/black-friday-cyber-monday',
  BRANDS = '/b',
  CART_BINS = '/cart/bins',
  CART_CLEAR = '/cart/clear',
  CATEGORIES = '/c',
  CONFIRM = '/confirm',
  DASHBOARD = '/dashboard',
  DEALS = '/deals',
  DELIVERY_RETURN = '/delivery-return',
  EMAIL_SENT = '/email-sent',
  FAQS = '/faqs',
  FISHING_ARTICLES = '/fishing-articles',
  FISHING_REPORTS = '/fishing-reports',
  FISHING_REPORTS_RULES = '/fishing-reports-contest-rules-results',
  FISHING_REPORTS_WEEKLY = '/fishing-reports-weekly-giveaway',
  GET_STARTED = '/best-way-to-fish',
  GO = '/go',
  HOME = '/',
  HOW_IT_WORKS = '/how-it-works',
  JACK_LINKS_BONUS = '/jack-links-bonus',
  JOBS = '/jobs',
  KITS = '/kits',
  LANDING_PAGES = '/lp',
  LISTS = '/l',
  LOGIN = '/login',
  MAP = '/map',
  NEW = '/new',
  NEWS = '/news',
  NEWSLETTER = '/subscribe',
  NEWSLETTER_SUCCESS = '/subscribed',
  OMINA_BLUE_LANDING_PAGE = '/omnia-blue',
  OMNIA_VIDEOS = '/omnia-videos',
  ONE_LINK_RESOLVER = '/forward-link',
  ORGS = '/orgs',
  PASSWORD_RESET = '/password-reset',
  PASSWORD_RESET_CONFIRM = '/password-reset-confirm',
  PERSONAL_SHOPPING = '/personal-shopping',
  PREMIUM = '/premium', // this route is not directly used for a page, but the route may be present in old OmniaVideos
  PREMIUM_PRO = '/pro',
  PREMIUM_PRO_PURCHASE_COMPLETE = '/pro-purchase-complete',
  PREMIUM_PRO_TRIAL_PURCHASE_COMPLETE = '/pro-trial-purchase-complete',
  PRIVACY = '/privacy',
  PRO_MEDIA = '/pro-media',
  PRO_OFFER = '/pro-offer',
  PRODUCTS = '/p',
  SALE = '/sale',
  SALE_PRO = '/sale-pro',
  SET_ALERTS = '/set-alerts',
  SHOP = '/shop',
  SIGNUP = '/signup',
  SIGNUP_FOLLOWUP = '/signup_followup',
  SIGNUP_GIVEAWAY = '/signup_giveaway',
  SIGNUP_GIVEAWAY_ENTERED = '/signup-giveaway-entered',
  SPECIES = '/sp',
  STATES = '/states',
  TECHNIQUES = '/st',
  TERMS = '/terms',
  TOURNAMENT_SUBMIT = '/tournament-submit',
  TOURNAMENTS = '/tournaments',
  USERS = '/u',
  VIDEOS = '/videos',
  WATERS = '/w',
  WE_SHIP_FAST = '/we-ship-fast',
  WHO = '/who',
  WILD_RIVER = '/wild-river-jerky',
  WISHLIST = '/wishlist',
  WISHLIST_GIVEAWAY = '/wishlist-giveaway',
}

export function getRoutePathByPathname(pathname: string) {
  const routeKeys = Object.keys(RoutePaths);
  const routePaths = routeKeys.map((k) => {
    return {
      key: k as keyof typeof RoutePaths,
      path: (RoutePaths as any)[k],
    };
  });

  const urlFirstLevel = '/' + pathname.split('/')[1];

  return routePaths.find((path) => path.path === urlFirstLevel);
}

// ========================================================================== //
// Dashboard routes
// ========================================================================== //

export enum DashboardPaths {
  ACCOUNT = 'account',
  CHANGE_EMAIL = 'change-email',
  EMAIL_PREFERENCES = 'email-preferences',
  FISHING_REPORTS = 'fishing-reports',
  GROUPS = 'groups',
  ORDERS = 'orders',
  PROFILE = 'profile',
  PROFILE_EDIT = 'profile/edit',
}

// ========================================================================== //
// Best of routes
// ========================================================================== //

export enum BestOfPaths {
  LAKES = '/lakes',
  STATES = '/states',
}

// ========================================================================== //
// Waterbody routes
// ========================================================================== //

export enum WaterbodyPaths {
  CURRENT_CONDITIONS = '/current-conditions',
  FISHING_REPORTS = '/fishing-reports',
}

// ========================================================================== //
// Variant URLs
// ========================================================================== //

export const OPTION_DIVIDER = '--';
export const VARIANT_KEY_VALUE_DIVIDER = '*';
export const WHITESPACE_REPLACEMENT = '~';
export const SLASH_REPLACEMENT = '__';
const slashRegex = new RegExp(SLASH_REPLACEMENT, 'g');
const whitespaceReplacementRegex = new RegExp(WHITESPACE_REPLACEMENT, 'g');

export function encodeVariantUrl(productHandle: string, selectedOptions: SelectedOption[]) {
  const options = _.orderBy(selectedOptions, 'name').map(
    (option) =>
      `${option.name.toLowerCase()}${VARIANT_KEY_VALUE_DIVIDER}${option.value
        .toLowerCase()
        .replace(/\s/g, WHITESPACE_REPLACEMENT)
        .replace(/\//g, SLASH_REPLACEMENT)}`
  );
  const optionsEncoded = encodeURIComponent(options.join(OPTION_DIVIDER));
  return `${productHandle}/${optionsEncoded}`;
}

export function decodeVariantUrl(variantOptions: string) {
  if (!variantOptions) {
    return null;
  }
  const url = decodeURIComponent(variantOptions);
  const options = url.split(OPTION_DIVIDER);

  const selectedOptions: SelectedOption[] = options.map((option) => {
    const keyValue = option.split(VARIANT_KEY_VALUE_DIVIDER);
    const value = keyValue
      .slice(1)
      .join(' ')
      .replace(slashRegex, '/')
      .replace(whitespaceReplacementRegex, ' ');
    return {
      name: keyValue[0],
      value,
    };
  });
  return selectedOptions;
}

// ========================================================================== //
// Page Types for tracking
// ========================================================================== //

export const PageTypes = {
  AMBASSADOR: 'Ambassador',
  ARTICLE: 'Article',
  BEST_SELLERS: 'Best Sellers',
  BRAND: 'Brand',
  CATEGORY_SUBCATEGORY: 'Product Category/Sub List',
  DASHBOARD: 'Dashboard',
  DYNAMIC_SEO: 'Dynamic SEO Page',
  FISHING_REPORTS: 'Fishing Reports',
  HOME: 'Home Page',
  HOW_IT_WORKS: 'How it Works',
  LAKE_PAGE: 'Lake Page',
  LANDING_PAGES: 'Landing Page',
  LIST: 'List',
  MAP: 'Map',
  NEWS: 'News',
  NEW_ARRIVALS: 'New Arrivals',
  ORDER_HISTORY: 'Order History',
  PREMIUM: 'Premium',
  PRODUCT_DETAIL: 'Product Detail',
  SALE: 'Bargain Bin',
  SEARCH_RESULT: 'Search Result',
  SIGNUP: 'Signup',
  SIGNUP_GIVEAWAY: 'Giveaway',
  SPECIES: 'Species',
  STATE: 'States',
  STYLE: 'Style',
  TOURNAMENT: 'Tournament',
  UNKNOWN: 'Unknown Type',
  VIDEOS: 'Videos',
  WISHLIST: 'Wishlist',
} as const;
export type PageTypes = typeof PageTypes[keyof typeof PageTypes];

export const OtherLocationTypes = {
  CART_UPSELL: 'Cart Upsell',
  DYNAMIC_KIT: 'Dynamic Kit',
} as const;
export type OtherLocationTypes = typeof OtherLocationTypes[keyof typeof OtherLocationTypes];

export type LocationTypes = PageTypes | OtherLocationTypes;

const routeKeyToPageMap = {
  [RoutePaths.ARTICLES]: PageTypes.ARTICLE,
  [RoutePaths.BRANDS]: PageTypes.BRAND,
  [RoutePaths.BEST_SELLERS]: PageTypes.BEST_SELLERS,
  [RoutePaths.CATEGORIES]: PageTypes.CATEGORY_SUBCATEGORY,
  [RoutePaths.DASHBOARD]: PageTypes.DASHBOARD,
  [RoutePaths.FISHING_REPORTS]: PageTypes.FISHING_REPORTS,
  [RoutePaths.HOME]: PageTypes.HOME,
  [RoutePaths.HOW_IT_WORKS]: PageTypes.HOW_IT_WORKS,
  [RoutePaths.LANDING_PAGES]: PageTypes.LANDING_PAGES,
  [RoutePaths.LISTS]: PageTypes.LIST,
  [RoutePaths.MAP]: PageTypes.MAP,
  [RoutePaths.PREMIUM]: PageTypes.PREMIUM,
  [RoutePaths.PRODUCTS]: PageTypes.PRODUCT_DETAIL,
  [RoutePaths.STATES]: PageTypes.STATE,
  [RoutePaths.SIGNUP]: PageTypes.SIGNUP,
  [RoutePaths.SIGNUP_GIVEAWAY]: PageTypes.SIGNUP_GIVEAWAY,
  [RoutePaths.SPECIES]: PageTypes.SPECIES,
  [RoutePaths.TECHNIQUES]: PageTypes.STYLE,
  [RoutePaths.TOURNAMENTS]: PageTypes.TOURNAMENT,
  [RoutePaths.USERS]: PageTypes.AMBASSADOR,
  [RoutePaths.WATERS]: PageTypes.LAKE_PAGE,
  [RoutePaths.WISHLIST]: PageTypes.WISHLIST,
  [RoutePaths.SALE]: PageTypes.SALE,
  [RoutePaths.NEW]: PageTypes.NEW_ARRIVALS,
  [RoutePaths.OMNIA_VIDEOS]: PageTypes.VIDEOS,
  [RoutePaths.VIDEOS]: PageTypes.VIDEOS,
} as Record<string, PageTypes>;

export function getPageTypeByPathname(url: string) {
  const routePath = getRoutePathByPathname(url);
  let pageType = PageTypes.UNKNOWN as PageTypes;
  if (routePath) {
    const pageTypeByUrl = routeKeyToPageMap[routePath.path];
    if (pageTypeByUrl) {
      pageType = pageTypeByUrl;
      if (pageType === PageTypes.STYLE) {
        const urlSplit = url.split('/');
        if (urlSplit.length === 4 && urlSplit[3] !== '') {
          pageType = PageTypes.DYNAMIC_SEO;
        }
      }
    }
  }

  return pageType;
}
