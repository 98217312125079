import { ClickKey_Footer } from '../../lib/web_analytics';
import { RoutePaths } from '../../routes';
import { previousYearPlusOneMonth } from '../../routes/best_of_year/best_of_year_page';

interface FooterLink {
  displayName: string;
  link: RoutePaths;
  webAnalyticsKeyClick: ClickKey_Footer;
}

const footerShop: FooterLink[] = [
  { displayName: 'New Arrivals', link: RoutePaths.NEW, webAnalyticsKeyClick: '[footer].(shop)' },
  {
    displayName: 'Best Sellers',
    link: RoutePaths.BEST_SELLERS,
    webAnalyticsKeyClick: '[footer].(shop)',
  },
  {
    displayName: 'Bargain Bin',
    link: RoutePaths.SALE,
    webAnalyticsKeyClick: '[footer].(shop)',
  },
  {
    displayName: 'PRO Sales',
    link: RoutePaths.SALE_PRO,
    webAnalyticsKeyClick: '[footer].(shop)',
  },
  {
    displayName: `Sales & Promotions`,
    link: RoutePaths.DEALS,
    webAnalyticsKeyClick: '[footer].(shop)',
  },
  {
    displayName: `Best of ${previousYearPlusOneMonth}`,
    link: RoutePaths.BEST_OF_YEAR,
    webAnalyticsKeyClick: '[footer].(shop)',
  },
];

const footerFishingResources: FooterLink[] = [
  {
    displayName: 'Fishing Reports',
    link: RoutePaths.FISHING_REPORTS,
    webAnalyticsKeyClick: '[footer].(content)',
  },
  {
    displayName: 'Fishing Articles',
    link: RoutePaths.FISHING_ARTICLES,
    webAnalyticsKeyClick: '[footer].(content)',
  },
  {
    displayName: 'Fishing Techniques',
    link: RoutePaths.TECHNIQUES,
    webAnalyticsKeyClick: '[footer].(content)',
  },
  {
    displayName: 'Fishing Species',
    link: RoutePaths.SPECIES,
    webAnalyticsKeyClick: '[footer].(content)',
  },
  {
    displayName: 'Fishing Tournaments',
    link: RoutePaths.TOURNAMENTS,
    webAnalyticsKeyClick: '[footer].(content)',
  },
  {
    displayName: 'Fishing Organizations',
    link: RoutePaths.ORGS,
    webAnalyticsKeyClick: '[footer].(content)',
  },
  {
    displayName: `Explore Lakes`,
    link: RoutePaths.MAP,
    webAnalyticsKeyClick: '[footer].(content)',
  },
  {
    displayName: 'Omnia PRO',
    link: RoutePaths.PREMIUM_PRO,
    webAnalyticsKeyClick: '[footer].(content)',
  },
  {
    displayName: 'Omnia App',
    link: RoutePaths.APP,
    webAnalyticsKeyClick: '[footer].(content)',
  },
];

const footerAbout: FooterLink[] = [
  {
    displayName: 'About Us',
    link: RoutePaths.ABOUT,
    webAnalyticsKeyClick: '[footer].(about)',
  },
  {
    displayName: 'Omnia News',
    link: RoutePaths.NEWS,
    webAnalyticsKeyClick: '[footer].(about)',
  },
  {
    displayName: 'Work at Omnia',
    link: RoutePaths.JOBS,
    webAnalyticsKeyClick: '[footer].(about)',
  },
  {
    displayName: 'Become an Ambassador',
    link: RoutePaths.AMBASSADOR_APPLICATION,
    webAnalyticsKeyClick: '[footer].(about)',
  },
];

const footerSupport: FooterLink[] = [
  {
    displayName: 'Talk to an Expert',
    link: RoutePaths.PERSONAL_SHOPPING,
    webAnalyticsKeyClick: '[footer].(support)',
  },
  {
    displayName: 'Shipping & Returns',
    link: RoutePaths.DELIVERY_RETURN,
    webAnalyticsKeyClick: '[footer].(support)',
  },
  {
    displayName: 'FAQs',
    link: RoutePaths.FAQS,
    webAnalyticsKeyClick: '[footer].(support)',
  },
];

export { footerShop, footerFishingResources, footerAbout, footerSupport };
