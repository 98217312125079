import { DollarCircleOutlined } from '@ant-design/icons';
import { Button, Dropdown, Menu } from 'antd';
import { default as classNames, default as classnames } from 'classnames';
import React, { useEffect, useMemo, useRef, useState } from 'react';
import { useSelector } from 'react-redux';
import { useHistory, useLocation } from 'react-router';
import { Link } from 'react-router-dom';
import {
  getNavigationGroups,
  NavigationGroupLowercase,
  NavigationGroupNames,
} from '../../categories_subcategories';
import { ClickKey_TopNav, WebAnalytics } from '../../lib/web_analytics';
import { hasAccessToken } from '../../redux/auth';
import { getCategories } from '../../redux/reference_data';
import { getIsMobile } from '../../redux/window';
import { RoutePaths } from '../../routes';
import SvgHamburgerWithCaret from '../svg/hamburger_with_caret';
import styles from './shop_menu.less';

export function ShopMenu() {
  const categories = useSelector(getCategories);
  const navGroups = getNavigationGroups(categories);
  const navRef = useRef(null);
  const [invisibilityMap, setInvisibilityMap] = useState<Record<string, boolean>>({});
  const history = useHistory();
  const isMobile = useSelector(getIsMobile);
  const isLoggedIn = useSelector(hasAccessToken);
  const location = useLocation();
  const menuRef = useRef();
  const [menuOpenKey, setMenuOpenKey] = useState('');

  const webAnalyticsTopNavHelper = (click_key: ClickKey_TopNav, target_url?: string) => {
    WebAnalytics.topNavClick({
      click_key,
      logged_in: isLoggedIn,
      target_url,
    });
  };

  const overflowMenu = (
    <Menu>
      {navGroups.map((group) => {
        if (invisibilityMap[group.name]) {
          const isDealsGroup = group.name === NavigationGroupNames.DEALS;
          return (
            <Menu.Item
              key={group.name}
              onClick={() => {
                if (group.url) {
                  history.push(group.url);
                } else {
                  const category = group.categories[0];
                  history.push(`${RoutePaths.CATEGORIES}/${category.url_path}`);
                }
              }}
            >
              {group.name}{' '}
              {isDealsGroup && (
                <span className={styles.dealsIcon}>
                  <DollarCircleOutlined />
                </span>
              )}
            </Menu.Item>
          );
        }
        return null;
      })}
    </Menu>
  );

  const shouldShowOverflowMenu = useMemo(
    () => Object.values(invisibilityMap).some((v) => v === true),
    [invisibilityMap]
  );

  const handleIntersection: IntersectionObserverCallback = (entries) => {
    const updatedInvisibilityMap: typeof invisibilityMap = {};
    entries.forEach((entry) => {
      const targetid = (entry.target as HTMLElement).dataset.targetid;
      updatedInvisibilityMap[targetid] = !entry.isIntersecting;
    });

    setInvisibilityMap((prev) => ({
      ...prev,
      ...updatedInvisibilityMap,
    }));
  };

  useEffect(() => {
    let observer: IntersectionObserver = null;
    if (isMobile) {
      if (observer) {
        observer.disconnect();
      }
    } else {
      observer = new IntersectionObserver(handleIntersection, {
        root: navRef.current,
        threshold: 0.95,
      });

      Array.from(navRef.current.children).forEach((item: HTMLElement) => {
        if (item.dataset.targetid && observer) {
          observer.observe(item);
        }
      });
    }

    return () => {
      if (observer) {
        observer.disconnect();
      }
    };
  }, [isMobile]);

  useEffect(() => {
    setMenuOpenKey(null);
  }, [location.pathname]);

  return (
    <div className={styles.wrapper}>
      <ul className={styles.list} ref={navRef}>
        {navGroups.map((group) => {
          const isDealsGroup = group.name === NavigationGroupNames.DEALS;
          // const isIceGroup = group.name === NavigationGroupNames.ICE;

          // if (isIceGroup) {
          //   return;
          // }
          const lowerCaseGroupName = group.name.toLowerCase() as NavigationGroupLowercase;
          const menu = (
            <div className={styles.dropdown}>
              <div className={styles.subcategories}>
                {group.name === NavigationGroupNames.BAIT ? (
                  <ul className={styles.dropdownList}>
                    {group.categories.map((category) => (
                      <li key={category.url_path}>
                        <Link
                          to={`${RoutePaths.CATEGORIES}/${category.url_path}`}
                          className={styles.dropdownLink}
                        >
                          {category.display_name}
                        </Link>
                      </li>
                    ))}
                  </ul>
                ) : (
                  <>
                    {group.categories.map((category) => (
                      <ul
                        className={styles.dropdownList}
                        style={{
                          columnCount: Math.ceil(category.subcategories.length / 6),
                        }}
                        key={category.url_path}
                      >
                        {category.subcategories
                          .filter((s) => !s.nav_hidden)
                          .map((subcategory) => (
                            <li key={subcategory.url_path} className={styles.dropdownItem}>
                              <Link
                                className={styles.dropdownLink}
                                to={`${RoutePaths.CATEGORIES}/${category.url_path}/${subcategory.url_path}`}
                              >
                                {subcategory.display_name}
                              </Link>
                            </li>
                          ))}
                      </ul>
                    ))}
                  </>
                )}
              </div>
              {group.sidebar != null && (
                <div className={styles.dropdownSidebar}>
                  <p className={styles.dropdownSidebarTitle}>{group.sidebar.title}</p>
                  <ul className={styles.dropdownSidebarList}>
                    {group.sidebar.items.map((item) => (
                      <li key={item.url}>
                        <Link to={item.url} className={styles.dropdownSidebarLink}>
                          {item.title}
                        </Link>
                      </li>
                    ))}
                  </ul>
                </div>
              )}
            </div>
          );

          return (
            <li
              key={group.name}
              data-targetid={group.name}
              className={classnames(styles.listItem, {
                [styles.invisible]: invisibilityMap[group.name],
              })}
            >
              {group.url ? (
                <Link
                  to={group.url}
                  className={styles.link}
                  onClick={() =>
                    webAnalyticsTopNavHelper(
                      `[top_nav].(${lowerCaseGroupName})`,
                      group.url ? group.url : group.name
                    )
                  }
                >
                  {group.name}{' '}
                  {isDealsGroup && (
                    <span className={styles.dealsIcon}>
                      <DollarCircleOutlined />
                    </span>
                  )}
                </Link>
              ) : (
                <>
                  <Dropdown
                    overlay={menu}
                    trigger={['click']}
                    open={menuOpenKey === group.name}
                    placement="bottom"
                    onOpenChange={(visible) => {
                      if (visible) {
                        setMenuOpenKey(group.name);
                      } else {
                        setMenuOpenKey(null);
                      }
                    }}
                  >
                    <a
                      href="#"
                      className={classNames(styles.link, {
                        [styles.link__selected]: menuOpenKey === group.name,
                      })}
                      onClick={(e: React.MouseEvent) => {
                        e.stopPropagation();
                        setMenuOpenKey(group.name);
                        webAnalyticsTopNavHelper(
                          `[top_nav].(${lowerCaseGroupName})`,
                          group.url ? group.url : group.name
                        );
                      }}
                      // FIXME: this is definately wrong, a single menuRef is being assigned to many nav groups...but its working. I think something like storing dynamicRefs in an array/object and creating a useClickOutside for each of them that responds only when the menuOpenKey matches...or something might be right
                      ref={menuRef}
                    >
                      {group.name}
                    </a>
                  </Dropdown>
                </>
              )}
            </li>
          );
        })}
      </ul>

      {shouldShowOverflowMenu && (
        <Dropdown
          overlay={overflowMenu}
          trigger={['click']}
          placement="bottomLeft"
          overlayClassName={styles.dropdownOverlay}
        >
          <Button className={styles.overflowButton} type="text">
            <SvgHamburgerWithCaret className={styles.overflowIcon} />
          </Button>
        </Dropdown>
      )}
    </div>
  );
}
